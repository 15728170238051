// Fixed element
@include keyframes(nextSwiper_aniamtion) {
	0% {
		bottom: 40px;
	}
	100% {
		bottom: 20px;
	} 
}
@mixin nextSwiper_aniamtion() {
	animation-name: nextSwiper_aniamtion;
    animation-duration: 1s;
    animation-delay: 200ms;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


// mascote portfolio
@include keyframes(mascote_portfolio) {
	0% {
		@include transforms(translateX(-40px) translateY(0));
	}
	30% {
		@include transforms(translateX(-70px) translateY(-20PX));
	} 
	100% {
		@include transforms(translateX(0px) translateY(10));
	} 
}
@mixin mascote_portfolio() {
	animation-name: mascote_portfolio;
    animation-duration: 4s;
    animation-delay: 0;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


// pulse 01
@include keyframes(pulse_1) {
	0% {
		@include transforms(scale(0.45), center);
		@include opacity(0.2);
	}
	100% {
		@include transforms(scale(0.7), center);
		@include opacity(0.8);
	} 
}
@mixin pulse_1() {
	animation-name: pulse_1;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

// pulse 02
@include keyframes(pulse_2) {
	0% {
		@include transforms(scale(0.1), center);
		@include opacity(0.9);
	}
	100% {
		@include transforms(scale(0.4), center);
		@include opacity(1);
	} 
}
@mixin pulse_2() {
	animation-name: pulse_2;
    animation-duration: 2.2s;
    animation-delay: 200ms;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

// pulse 03
@include keyframes(pulse_3) {
	0% {
		@include transforms(scale(0.75), center);
		@include opacity(0.8);
	}
	100% {
		@include transforms(scale(1), center);
		@include opacity(1);
	} 
}
@mixin pulse_3() {
	animation-name: pulse_3;
    animation-duration: 1.5s;
    animation-delay: 400ms;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}