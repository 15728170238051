//
// Fixed elements
//
@media #{$screen}#{$maxw_1170}{
	#fp-nav.fp-right {right: 15px; bottom: 20px;}
	.fixedEl {
		$defaultSpace: 20px;
		&__brandMenu {
			top: $defaultSpace;
			left: $defaultSpace;
			&.scrolled {
				&:before {
					position: fixed;
					top: 0;
					left: 0;
					width: 100vw;
					height: 80px;
					background: rgba(0,0,0,0.9);
					content: '';
					display: block;
					z-index: 0;
				}
			}
			.c-hamburguer {
				top: 1px;
				width: 30px;
				.c-hamburguer__hero {
					width: 100%;
				}
			}
			.brand {
				position: relative;
				z-index: 1;
				padding-left: 15px;
				a img {
					height: 40px;
				}
			}
		}
		&__btnContato {
			top: $defaultSpace;
			right: $defaultSpace;
		}
		&__socialMenu {bottom: $defaultSpace; left: $defaultSpace;}
		&__swiperNext {bottom: $defaultSpace;}
		&__btnMute {right: $defaultSpace;}
		&__btnPlay {right: $defaultSpace;}
		&__swiperControl {right: $defaultSpace; bottom: $defaultSpace;}
	}
	.fixedEl__swiperNext {
		display: none;
	}
}
@media #{$screen}#{$maxw_1170}{
	.fixedEl__socialMenu {
		ul {
			li {
				display: inline-block;
			}
		}
		a {
			&:hover {
				.socialMenu__caption {
					left: 0px;
					top: -30px;
				}
			}
		}
	}
}
@media #{$screen}#{$maxw_780}{
	#fp-nav.fp-right {right: 15px; bottom: 20px;}
	.fixedEl {
		$defaultSpace: 15px;
		&__brandMenu {
			top: $defaultSpace;
			left: $defaultSpace;
			&.scrolled {
				&:before {
					height: 65px;
				}
			}
			.c-hamburguer {
				top: 1px;
				width: 30px;
				.c-hamburguer__hero {
					width: 100%;
				}
			}
		}
		&__btnContato {
			top: $defaultSpace;
			right: $defaultSpace;
			a {
				height: 34px;
				line-height: 34px;
				width: 160px;
			}
		}
		&__socialMenu {bottom: $defaultSpace; left: $defaultSpace;}
		&__swiperNext {bottom: $defaultSpace;}
		&__btnMute {right: $defaultSpace; bottom: 20px; @include transforms(translateY(0)); top: inherit;}
		&__btnPlay {right: 70px; bottom: 20px; margin: 0; @include transforms(translateY(0)); top: inherit;}
		&__swiperControl {right: $defaultSpace; bottom: $defaultSpace;}
	}
}
@media #{$screen} and (max-width: 435px){
	.fixedEl__btnContato {display: none;}
}
@media #{$screen}#{$maxh_850}{
	.fixedEl__socialMenu {
		ul {
			li {
				display: inline-block;
			}
		}
	}
}

//
// Nav Menu - Hamburguer ON
//
@media #{$screen}#{$maxw_780} {
	.fixedEl__navMenu.is-open {
		left: 0;
		width: 100%;
		max-width: 280px;
		padding-top: 80px;
		ul {
			left: 0;
		}
		a {
			padding: 10px 15px;
		}
	}
	.fixedEl__navMenu a {
		font-size: 40px;
		padding: 10px 60px;
	}
}
@media #{$screen}#{$maxh_600} {
	.fixedEl__navMenu.is-open {
		left: -120px;
		padding-top: 80px;
	}
}
@media #{$screen} and (max-height: 420px) {
	.fixedEl__navMenu.is-open {
		left: -90px;
		padding-top: 65px;
	}

	.fixedEl__navMenu a {
		font-size: 22px;
		padding: 8px 60px;
	}
}

//
// All Pages
//
@media #{$screen}#{$maxw_1280}{
	#quem-somos h2,
	#portfolio .titleSection h2,
	#contato h2 {
		font-size: 70px;
	}
}
@media #{$screen}#{$maxw_1170}{
	.pageSection {
		display: block !important;
		height: auto !important;
		min-height: auto !important;
		background: #000;
		.fp-tableCell {
			display: block !important;
			height: auto !important;
			min-height: auto !important;
		}
	}

	#quem-somos,
	#portfolio,
	#contato {
		padding: 120px 0;
	}
}
@media #{$screen}#{$maxw_850}{
	#quem-somos h2,
	#portfolio .titleSection h2,
	#contato h2 {
		font-size: 50px;
	}
}
@media #{$screen}#{$maxh_680} {
	#quem-somos h2,
	#portfolio .titleSection h2,
	#contato h2 {
		font-size: 50px;
	}
}

//
// Home
//
@media #{$screen}#{$maxw_1170}{
	#home {
		height: 100vh !important;
	}
}


//
// Quem somos
//
@media #{$screen}#{$maxw_1280}{
	#quem-somos p {
		max-width: 600px;
		font-size: 20px;
	}
	#quem-somos .photoGallery {
		max-width: 700px;
	}
	#quem-somos .floatImg {
		top: -100px;
	}
}
@media #{$screen}#{$maxw_1030}{
	#quem-somos .floatImg {
		max-width: 350px;
		top: 0;
	}
}
@media #{$screen}#{$maxw_940}{
	#quem-somos p {
		max-width: 320px;
		font-size: 18px;
	}
}
@media #{$screen}#{$maxw_640}{
	#quem-somos:after {
		display: none;
	}
	#quem-somos .floatBoxes {
		display: none;
	}
	#quem-somos p {
		max-width: 100%;
		font-size: 20px;
	}
	#quem-somos .floatImg {
		display: none;
	}
}
@media #{$screen}#{$maxh_850}{
	#quem-somos .floatImg {
		max-width: 440px;
	}
	#quem-somos p {
		font-size: 22px;
	}
	#quem-somos .photoGallery {
		max-width: 700px;
	}
}
@media #{$screen}#{$maxh_680}{
	#quem-somos .floatImg {
		top: -30px;
		max-width: 370px;
	}
	#quem-somos .fp-scrollable {
		display: flex;
		align-items: center;
		.fp-scroller {
			overflow: unset;
			overflow: initial;
			flex: 1 1 100%;
			width: 100%;
		}
	}
}


//
// Portfolio
//
@media #{$screen}#{$maxw_1370}{
	#portfolio .titleSection .floatImg {
		max-width: 380px;
	}
	#portfolio .gridJobs ul li .jobCard {
		height: 340px;
	}
	#portfolio .gridJobs ul li .jobCard__title h3 {
		font-size: 24px;
	}
}
@media #{$screen}#{$maxw_1280}{
	#portfolio .titleSection .floatImg {
		display: none;
	}
}
@media #{$screen} and (max-width: 1210px){
	#portfolio .gridJobs ul li {
		width: 33.3%;
	}
}
@media #{$screen}#{$maxw_1170}{
	#portfolio .gridJobs ul li .jobCard {
		height: 320px;
	}
	#portfolio .titleSection {
		padding-top: 0;
	}
	#portfolio {
		padding-bottom: 0;
	}
}
@media #{$screen}#{$maxw_940}{
	#portfolio .gridJobs ul li .jobCard__title h3 {
		font-size: 20px;
	}
	#portfolio .gridJobs ul li .jobCard .jobCard__infos {
		bottom: 0;
		padding: 12px 15px;
		border-left: 1px solid $primaryDark;
	}
}
@media #{$screen}#{$maxw_850}{
	#portfolio .gridJobs ul li .jobCard {
		height: 300px;
	}
}
@media #{$screen}#{$maxw_780}{
	#portfolio .gridJobs ul li {
		width: 50%;
	}
}


// 
// Contato
// 

@media #{$screen}#{$maxw_1170} {
	#contato .mapboxgl-ctrl-top-right {
		right: 20px;
	}
}
@media #{$screen}#{$maxw_1030}{
	#contato {
		padding: 0;
	}
	#contato .u-container {
		padding: 100px 15px;
		background: #1d191b;
		width: 100% !important;
		display: block !important;
		left: 0 !important;
		background: url('../../media/img/assets/bg_jobs.jpg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
	#contato #map {
		position: relative;
		height: 400px;
		max-height: 75vh;
		width: 100%;
	}
}
@media #{$screen}#{$maxw_780} {
	#contato .mapboxgl-ctrl-top-right {
		right: 15px;
	}
}

//
// Contato Modal
//
@media #{$screen}#{$maxw_1170} {
	#contato .infosContato li {
		display: block !important;
	}
}
@media #{$screen}#{$maxw_780} {
	#contatoModal .contatoModal__hero {
		max-height: 80vh;
		overflow: auto;
	}
	#contatoModal .contatoModal__formulario li {
		width: 100%;
	}
	#contatoModal {
		padding: 15px;
	}
	#contatoModal .contatoModal__hero:after {
		display: none;
	}
	#contatoModal .contatoModal__header {
		padding: 15px 20px;
	}
	#contatoModal .contatoModal__header h2 {
		font-size: 22px;
		line-height: 38px;
	}
	#contatoModal .contatoModal__formulario {
		padding: 20px 5px;
	}
}


// 
// job modal
//
@media #{$screen}#{$maxw_780} {
	#jobModal {
		padding: 0px;
	}
	#jobModal .jobModal__header {
		padding: 15px 20px;
	}
	#jobModal .jobModal__header h2 {
		font-size: 22px;
	}
	#jobModal .jobModal__header .jobModal__controls span {
		height: 22px;
		width: 22px;
		position: relative;
		top: 2px;
		img {
			height: 12px;
			position: relative;
			top: -6px;
		}
	}

	#jobModal .jobModal__beforeAfter .pat1,
	#jobModal .jobModal__beforeAfter .pat2 {
		height: 320px;
	}
	#jobModal .jobModal__beforeAfter {
		height: 320px;
	}
	#jobModal .jobModal__quote {
		padding: 30px;
	}
	#jobModal .jobModal__video iframe {
		height: 320px;
	}
}