body {
	@include scroll($bg: $secondary, $bar: $primaryLight, $size: 5px, $radius: 0px);
	background: $secondary;
}

.laures__loader--contentHide {
	@include transition();
	@include opacity(1);
}

.fixedEl {
	$defaultSpace: 40px;
	display: block;
	position: fixed;
	z-index: 2;

	&__brandMenu {
		top: $defaultSpace;
		left: $defaultSpace;
		.c-hamburguer {
			float: left;
		}
		.brand {
			float: left;
			padding-left: 40px;
			a {
				display: block;
				img {
					height: 60px;
					width: auto;
					&.whiteBrand {
						display: block;
					}
					&.purpleBrand {
						display: none;
					}
				}
			}
		}
	}

	&__btnContato {
		top: $defaultSpace;
		right: $defaultSpace;
		a {
			@include btnContato();
		}
	}

	&__socialMenu {
		bottom: $defaultSpace;
		left: $defaultSpace;
		li {
			margin-top: 10px;
			&:first-of-type {
				margin-top: 0;
			}
		}
		a {
			@include transition($ease: ease-in-out, $duration: 150ms);
			@include transforms(scale(1), center);
			display: block;
			height: 32px;
			width: 32px;
			position: relative;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			z-index: 2;
			&:hover {
				@include transforms(scale(1.1), center);
			}
			img {
				display: block;
				width: 32px;
				height: 32px;
			}
			&.i-fb {
				background: url(../../media/img/icons/icon_fb.png);
			}
			&.i-in {
				background: url(../../media/img/icons/icon_insta.png);
			}
			&.i-yt {
				background: url(../../media/img/icons/icon_yt.png);
			}
			.socialMenu__caption {
				@include transition();
				@include u-hideElement();
				display: block;
				height: 22px;
				width: 90px;
				padding-left: 10px;
				color: $secondary;
				font-size: 12px;
				font-weight: $fwBold;
				line-height: 22px;
				text-align: left;
				text-transform: uppercase;
				background: $primary;
				position: absolute;
				left: 0;
				top: 4px;
				z-index: 1;
			}
			&:hover {
				.socialMenu__caption {
					@include u-showElement();
					left: 32px;
				}
			}
		}
	}

	&__swiperNext {
		@include transforms(translateX(-50%),center);
		@include transition();
		@include nextSwiper_aniamtion();
		bottom: $defaultSpace;
		cursor: pointer;
		left: 50%;
		span {
			@include transition($ease: ease-in-out, $duration: 150ms);
			background: $primary;
			color: $secondaryLight;
			display: inline-block;
			font-size: 12px;
			font-weight: $fwBold;
			height: 26px;
			line-height: 26px;
			padding: 0 10px;
			text-align: center;
			text-transform: uppercase;
			z-index: 1;
			&:before {
				content: '';
				display: block;
				height: 26px;
				background: $secondaryLight;
				width: 100%;
				position: absolute;
				left: 2px;
				top: 2px;
				z-index: -1;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				top: 25px;
				@include transforms(translateX(-50%),center);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 7px 0 7px;
				border-color: $primary transparent transparent transparent;
			}
		}
	}

	&__btnMute,
	&__btnPlay {
		@include transforms(translateY(-50%),center);
		@include transition();
		background: $primary;
		cursor: pointer;
		height: 40px;
		position: fixed;
		right: $defaultSpace;
		top: 50%;
		width: 40px;
		i {
			display: block;
			height: 20px;
			left: 10px;
			position: relative;
			top: 10px;
			width: 20px;
			&:before, &:after {
				@include roundedAll(10px);
				@include transition();
				@include transforms(rotate(0deg),center);
				content: '';
				display: block;
				position: absolute;
				right: 0;
				top: 4px;
				height: 12px;
				width: 6px;
				border-right: 2px solid $secondaryDark;
			}
			&:after {
				top: 6px;
				height: 8px;
				width: 4px;
				right: 4px;
			}

			&.is-muted {
				&:before, &:after {
					background: $secondaryDark;
					top: 9px;
					right: -4px;
					width: 10px;
					height: 2px;
				}
				&:before {
					@include transforms(rotate(45deg),center);
				}
				&:after {
					@include transforms(rotate(-45deg),center);
				}
			}
		}
		svg {
			display: block;
			width: 20px;
			height: 20px;
			path {
				fill: $secondary;
			}
		}
		&.hide {
			right: -200px;
		}
	}

	&__btnPlay {
		margin-top: 50px;
		display: none;
		&--inMobile {
			display: block;
		}
		i {
			position: relative !important;
			left: 10px !important;
			&:after,
			&:before {
				display: none !important;
			}
		}
	}

	&__swiperControl {
		right: $defaultSpace;
		bottom: $defaultSpace;
		.swiper-pagination {
			position: relative;
			.swiper-pagination-bullet {
				@include roundedAll(0);
				@include opacity(1);
				background: $secondaryLight;
				height: 10px;
				margin-left: 10px;
				position: relative;
				width: 50px;
				&.swiper-pagination-bullet-active {
					@include opacity(1);
					background: $primary;
				}
				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}

	&__navMenu {
		@include transition();
		@include opacity(0);
		background-color: $primary;
		background-image: -moz-linear-gradient( 45deg, rgb(234,207,50) 0%, rgb(249,221,58) 100%);
		background-image: -ms-linear-gradient( 45deg, rgb(234,207,50) 0%, rgb(249,221,58) 100%);
		background-image: -webkit-linear-gradient( 45deg, rgb(234,207,50) 0%, rgb(249,221,58) 100%);
		box-shadow: 150px 6px 150px 60px rgba(69, 15, 52, 0.15);
		height: 100%;
		left: -640px;
		overflow: hidden;
		padding-top: 140px;
		top: 0;
		width: 450px;
		z-index: 1;
		&.is-open {
			@include opacity(1);
			left: -100px;
		}
		ul {
			position: relative;
			left: 100px;
		}
		a {
			@include transition($duration: 150ms);
			background: transparent;
			color: $secondary;
			display: block;
			font-size: 54px;
			font-weight: $fwLight;
			line-height: 1.2;
			padding: 15px 40px;
			text-transform: uppercase;
			width: 100%;
			&:hover, &.active {
				background-color: $secondary;
				background-image: -moz-linear-gradient( -40deg, rgb(132,40,101) 53%, rgb(118,29,87) 100%);
				background-image: -ms-linear-gradient( -40deg, rgb(132,40,101) 53%, rgb(118,29,87) 100%);
				background-image: -webkit-linear-gradient( -40deg, rgb(132,40,101) 53%, rgb(118,29,87) 100%);
				color: $primary;
			}
		}
	}
	
	&.fixedEl--navOpen {

	}

	&.fixedEl--yellowBg {
		&.fixedEl__brandMenu {
			.c-hamburguer .c-hamburguer__hero .bar {
				background: $secondary;
			}
			.brand {
				a {
					img {
						&.whiteBrand {
							display: none;
						}
						&.purpleBrand {
							display: block;
						}
					}
				}
			}
		}
		&.fixedEl__btnContato {
			a {
				background: $secondary;
				color: $branco;
				&:hover {
					color: $branco;
					background: $primaryDark;
				}
			}
		}
		&.fixedEl__swiperNext {
			span {
				color: $secondary;
				background: $branco;
				&:after {
					border-color: $branco transparent transparent transparent;
				}
			}
		}
	}
}

#fullpage {
	@include transition();
	left: 0;
	&.rotateNavOpen {
		left: 150px;
	}
}

#fp-nav.fp-right {
	right: 40px;
	bottom: 45px;
	top: inherit;
	margin: 0 !important;
	ul {
		display: block;
		height: 10px;
		li {
			display: inline-block;
			height: 10px;
			margin: 0;
			margin-left: 10px;
			width: 50px;
			a {
				@include roundedAll(0);
				background: $secondary;
				height: 10px;
				position: relative;
				width: 100%;
				z-index: 2;
				&:after {
					@include comicCardPadrao();
					background: $secondaryLight;
					top: -2px;
					left: -2px;
				}
				span {display: none;}
				&.active {
					background: $secondaryDark;
					&:after {
						background: $primary;
					}
				}
			}
		}
	}
}

.fullHeight {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;

	height: 100vh;
	overflow: hidden;
	position: relative;
	max-width: 100%;
	margin: 0 auto;
}

.pageSection {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
}

#home {
	background-color: $secondaryDark;
	background-image: url('../../media/img/assets/bg_header.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	.patternVideo {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('../../media/img/assets/patternVideo.png');
		background-repeat: repeat;
		background-size: 5px;
		z-index: 2;
		top: 0;
		left: 0;
		@include opacity(0.3);
	}
	#videoHome {
	    @include opacity(0);
	    @include transforms(translateX(50%) translateY(-50%), center);
	    @include transition();
	   	min-height: 100%;
	   	min-width: 100%;
	    position: absolute;
	    right: 50%;
	    top: 50%;
	    &.videoHome--show {
	    	@include opacity(1);
	    }
	}
}

#quem-somos {
	background-color: $primary;
	&:after {
		display: block;
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		background: url('../../media/img/assets/bg_sobre_diagonalWhite.png');
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		background-position: center right;
		background-size: contain;
		z-index: 1;
	}
	.fp-scrollable {
		position: relative;
		z-index: 2;
	}
	.u-container {
		z-index: 2;
	}
	h2 {
		@include titleMaster($color: $secondary);
	}
	p {
		@include subTitleMaster();
	}
	h3 {
		display: block;
		position: relative;
		margin-top: 30px;
		margin-bottom: 20px;
		width: 120px;
		&:after {
			@include comicCardPadrao();
		}
		span {
			position: relative;
			display: block;
			z-index: 2;
			height: 32px;
			font-size: 12px;
			line-height: 32px;
			text-align: center;
			text-transform: uppercase;
			background: $branco;
			color: $secondary;
			font-weight: $fwExtraBold;
			width: 100%;
			z-index: 2;
		}
	}
	.photoGallery {
		display: block;
		width: 100%;
		max-width: 900px;
		overflow: hidden;
		margin-top: 20px;
		.photoGallery__swiper {
			width: 100%;
			display: block;
			padding-right: 3px;
			.swiper-slide {
				display: block;
				a, img {
					@include opacity(1);
					@include pb(0%);
					@include roundedAll(2px);
					@include transition();
					display: block;
					position: relative;
					width: 100%;
					z-index: 3;
				}
				a {
					background: $secondary;
					&:after {
						@include comicCardPadrao();
					}
					&:hover {
						img {
							@include opacity(0.5);
							@include pb(100%);
						}
					}
				}
			}
			.photoGallery__pagination {
				display: block;
				text-align: right;
				margin-top: 15px;
				.swiper-pagination-bullet {
					@include roundedAll(0);
					@include transition();
					background: $secondary;
					display: inline-block;
					height: 12px;
					margin-left: 8px;
					position: relative;
					position: relative;
					width: 12px;
					@include opacity(1);
					&:after {
						@include comicCardPadrao();
						background: $secondaryLight;
						top: -3px;
						left: -3px;
					}
					&:first-of-type {
						margin-left: 0;
					}
					&.swiper-pagination-bullet-active {
						&:after {
							background: $secondaryDark;
						}
					}
				}
			}
		}
	}
	.floatImg {
		position: absolute;
		display: block;
		max-width: 550px;
		width: 100%;
		right: 0;
		top: 0;
		img {
			display: block;
			width: 100%;
			position: relative;
			top: 30px;
		}
	}
	.floatBoxes {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.floatSquare {
			position: absolute;
			display: block;
			width: 120px;
			height: 120px;
			border-width: 8px;
			border-style: solid;
			&--1 {
				border-color: $primary;
				top: 60px;
				left: 50%;
				@include transforms(translateX(320px),center);
				display: none;
			}
			&--2 {
				border-color: $secondary;
				top: 180px;
				left: 50%;
				@include transforms(translateX(200px),center);
				display: none;
			}
			&--3 {
				border-color: $secondaryLight;
				top: 200px;
				left: 50%;
				@include transforms(translateX(400px),center);
				display: none;
			}
			&--4 {
				border-color: $branco;
				top: 50%;
				left: 50%;
				@include transforms(translateX(180px) translateY(-90px),center);
				display: none;
			}
			&--5 {
				border-color: $primary;
				bottom: 180px;
				left: 70px;
				display: none;
			}
			&--6 {
				border-color: $secondary;
				background: $secondary;
				bottom: 70px;
				left: 180px;
			}
		}
	}
}

#portfolio {
	background-image: url('../../media/img/assets/bg_jobs.jpg');
	background-color: $secondaryDark;
	background-repeat: no-repeat;
	background-position: center top;
	//background-size: cover;
	-webkit-box-align: top;
	-ms-flex-align: top;
	-webkit-align-items: top;
	align-items: top;
	@include scroll($bg: $secondary, $bar: $primaryLight, $size: 5px, $radius: 0px);
	.gridJobs, .titleSection {
		display: block;
		width: 100%;
	}
	.titleSection {
		padding-top: 200px;
		h2 {
			@include titleMaster();
		}
		ul {
			margin-top: 40px;
			margin-bottom: 70px;
			li {
				display: inline-block;
				margin-right: 5px;
				&:last-of-type {
					margin-right: 0;
				}
				a {
					@include transition();
					@include roundedAll(2px);
					background-color: transparent;
					color: $branco;
					display: block;
					padding: 10px 15px;
					&:hover {
						background: $secondaryLight;
					}
				}
				span {
					display: inline-block;
					font-size: 20px;
					font-weight: $fwLight;
					padding-left: 8px;
				}
				i {
					display: inline-block;
					height: 20px;
					line-height: 20px;
					position: relative;
					top: 2px;
					width: auto;
					img {
						display: block;
						width: auto;
						height: 20px;
					}
				}
				&.active {
					a {
						background: $secondary;
					}
				}
			}
		}
		.floatImg {
			position: absolute;
			display: block;
			max-width: 420px;
			width: 100%;
			right: 40px;
			top: 0;
			@include mascote_portfolio();
			img {
				display: block;
				width: 100%;
			}
		}
	}
	.gridJobs {
		ul {
			@include transition();
			display: -ms-flexbox;
		    display: -webkit-flex;
		    display: flex;
		    -webkit-flex-direction: row;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		    -webkit-justify-content: flex-start;
		    -ms-flex-pack: start;
		    justify-content: flex-start;
		    -webkit-align-content: flex-start;
		    -ms-flex-line-pack: start;
		    align-content: flex-start;
		    -webkit-align-items: flex-start;
		    -ms-flex-align: start;
    		align-items: flex-start;
    		text-align: left;
			li {
				@include transforms(scale(1), center);
				@include transition($duration: 200ms);
				@include u-showElement();
				display: block;
				width: 25%;
				order: 2;
				.jobCard {
					@include transition();
					display: block;
					position: relative;
					overflow: hidden;
					width: 100%;
					height: 480px;
					max-height: 50vh;
					background: $secondaryDark;
					&__bg {
						@include transition();
						@include opacity(0.3);
						@include pb(0.3);
						@include transforms(rotate(0) scale(1), center);
						display: block;
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						background-repeat: no-repeat !important;
						background-size: cover !important;
						background-position: center center !important;
					}
					&__infos {
						@include transition();
						padding: 25px 30px;
						background: $primary;
						display: block;
						position: absolute;
						bottom: -250px;
						left: 0;
						width: 100%;
					}
					&__tag, &__title, &__date {
						color: $secondary;
						text-transform: uppercase;
						display: block;
						width: 100%;
						i {
							width: 14px;
							height: 14px;
							line-height: 14px;
							margin-right: 2px;
							display: inline-block;
							position: relative;
							top: 3px;
							img, svg {
								display: block;
								width: auto;
								height: auto;
								max-width: 14px;
								max-height: 14px;
							}
						}
						span {
							height: 14px;
							display: inline-block;
							line-height: 14px;
							font-weight: $fwBold;
							font-size: 12px;
						}
					}
					&__title {
						margin: 2px 0 0 0;
						h3 {
							font-weight: $fwRegular;
							font-size: 32px;
						}
					}
					&:hover {
						.jobCard__bg {
							top: -100px;
							@include opacity(1);
							@include pb(0);
							@include transforms(rotate(0deg) scale(1.05), center);
						}
						.jobCard__infos {
							bottom: 0;
						}
					}
				}
				&.js-hideAllGrids {
					@include opacity(0);
					@include transforms(scale(0), center);
				}
				&.js-alignGrids {
					order: 1 !important;
				}
				&.js-selectedGrids {
					@include opacity(1);
					@include transforms(scale(1), center);
				}
			}
		}
	}
}

#contato {
	position: relative;
	.copyrightSite {
		position: absolute;
		display: block;
		bottom: 0;
		right: 0;
		padding: 4px 8px;
		z-index: 5;
		background: rgba(00,00,00,.5);
		color: $branco;
		font-size: 11px;
		font-weight: $fwRegular;
		a {
			color: $primary;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	#map {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $secondary;
		z-index: 1;
	}
	#mapMaker {
		height: 94px;
		width: 50px;
		display: block;
	    background: url(../../media/img/icons/icon_map.png);
	    background-repeat: no-repeat;
	    background-position: center top;
	    .animation {
	    	position: relative;
	    	margin-left: 10px;
	    	margin-top: 64px;
	    	display: block;
	    	width: 30px;
	    	height: 30px;
	    }
	    .animation > div {
	    	@include roundedAll(50%);
	    	@include transforms(scale(0), center);
	    	@include transition();
	    	display: block;
	    	position: absolute;
	    	&:nth-child(3) {
	    		@include pulse_3();
	    		background: $primaryLight;
	    		height: 20px;
	    		width: 20px;
	    		left: 5px;
	    		top: 5px;
	    		z-index: 3;
	    	}
	    	&:nth-child(2) {
	    		@include pulse_2();
	    		background: $primary;
	    		height: 24px;
	    		width: 24px;
	    		left: 3px;
	    		top: 3px;
	    		z-index: 2;
	    	}
	    	&:nth-child(1) {
	    		@include pulse_1();
	    		background: $primaryDark;
	    		height: 30px;
	    		width: 30px;
	    		top: 0;
	    		left: 0;
	    		z-index: 1;
	    	}
	    }
	}
	.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-logo{
		display: none;
	}
	.mapboxgl-ctrl-top-right {
		right: 40px;
		top: 50%;
		@include transforms(translateY(-50%), center);
	}
	.mapboxgl-ctrl-group {
		@include roundedAll(0);
		margin: 0;
		background: $primary;
		> button {
			width: 40px;
			height: 40px;
			border: 0;
			&:last-of-type {
				display: none;
			}
		}
	}

	.u-container {
		position: relative;
		z-index: 2;
	}

	h2 {
		@include titleMaster($color: $primary);
	}

	.infosContato {
		li {
			display: block;
			width: 100%;
			overflow: hidden;
			margin-top: 30px;
			i {
				display: block;
				float: left;
				width: 55px;
				img {
					display: block;
					width: 100%;
				}
			}
			div {
				@include calc(width, 100%, '-', 55px);
				display: block;
				float: left;
				padding-left: 15px;
			}
			h3 {
				color: $primary;
				display: block;
				font-size: 14px;
				font-weight: $fwBold;
				margin-bottom: 6px;
				text-transform: uppercase;
			}
			a, p {
				color: $branco;
				display: inline-block;
				font-size: 20px;
				font-weight: $fwLight;
			}
			a {
				&:hover {
					text-decoration: underline;
					color: $primaryLight;
				}
			}
		}
		.btnEmail {
			span {
				cursor: pointer;
				@include btnContato();
			}
		}
	}
}

.fancybox-slide {
	@include scroll($bg: $secondary, $bar: $primaryLight, $size: 5px, $radius: 0px);
}

.jobModal__loader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 201;

	display: block;
	width: 100%;
	height: 100%;
	background: rgba(41,4,29,0.9);
	
	color: $branco;
	text-align: center;
	@include u-hideElement();
	&.active {
		@include u-showElement();
	}
	.animBox {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transforms(translateY(-50%) translateX(-50%), center)
	}
	.anim {
		@include transforms(scale(0), center);
		@include transition();
		background-color: transparent;
		display: block;
		position: absolute;
		&:nth-child(3) {
			@include pulse_3();
			border: 5px solid $primary;
			background: $primary;
			height: 60px;
			width: 60px;
			z-index: 1;
		}
		&:nth-child(2) {
			@include pulse_2();
			border: 5px solid $primaryLight;
			background: $primaryLight;
			height: 40px;
			width: 40px;
			left: 10px;
			top: 10px;
			z-index: 3;
		}
		&:nth-child(1) {
			@include pulse_1();
			border: 5px solid $branco;
			background: $branco;
			height: 30px;
			width: 30px;
			top: 15px;
			left: 15px;
			z-index: 2;
		}
	}
}

#jobModal, #contatoModal {
	@include u-hideElement();

	display: block;
	width: 100%;
	height: 100%;
	max-height: 80vh;

	overflow: auto;
	@include scroll($bg: $secondary, $bar: $primaryLight, $size: 5px, $radius: 0px);
	
	padding: 0 30px;
	margin: 0 auto;
	
	position: fixed;
	left: 0;
	top: 10vh;
	z-index: 200;
	&.active {
		@include u-showElement();
	}
	.jobModal__mask, .contatoModal__mask {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: rgba(41,4,29,0.9);
	}
	.jobModal__hero, .contatoModal__hero {
		@include opacity(1);
		@include transition();
		margin: 0 auto;
		max-width: 1100px;
		position: relative;
		width: 100%;
		z-index: 2;
		&.transitionNextPrev {
			@include opacity(0);
		}
	}
	.jobModal__header, .contatoModal__header {
		display: block;
		width: 100%;
		background: $primary;
		padding: 25px 30px;
		overflow: hidden;
		h2 {
			@include calc(width, 100%, '-', 130px);
			color: $secondary;
			display: block;
			float: left;
			font-size: 32px;
			font-weight: $fwBold;
			line-height: 1.2;
			text-transform: uppercase;
		}
		.jobModal__controls, .contatoModal__controls {
			display: block;
			overflow: hidden;
			float: left;
			width: 130px;
			text-align: right;
			span {
				@include transition();
				border: 1px solid transparent;
				cursor: pointer;
				display: inline-block;
				height: 38px;
				padding-top: 8px;
				text-align: center;
				width: 38px;
				&:hover {
					border: 1px solid $primaryDark;
				}
				svg, img {
					display: inline-block;
					height: 22px;
				}
			}
		}
	}
	.jobModal__beforeAfter {
		position: relative;
		height: 480px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		.jobBeforeAfter {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.beer-handle {
			@include roundedAll(0);
			@include transition($duration: 0s);
			// @include transforms(translateY(-50%), translateX(-50%));
			background-color: transparent;
			background-position: center center;
			background-repeat: no-repeat;
			background: url('../../media/img/assets/dragControl.png');
			bottom: 50%;
			box-shadow: 0;
			height: 116px;
			width: 116px;
			&:before, &:after {
				display: none;
			}
		}
		.beer-range:focus~.beer-handle {
		    background: url('../../media/img/assets/dragControl.png');
		    box-shadow: none;
		}
		.pat1, .pat2 {
			background-position: center center !important;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			background-color: $secondary;
			width: 100%;
			height: 480px;
		}
	}
	.jobModal__video {
		display: block;
		background: $secondary;
		iframe {
			display: block;
			width: 100%;
			height: 620px;
			max-height: 100vh;
		}
	}
	.jobModal__presentation {
		display: block;
		a, img {
			display: block;
			width: 100%;
		}
	}
	.jobModal__quote {
		background: $secondaryDark;
		padding: 80px;
		p {
			color: $branco;
			font-weight: $fwLight;
			font-size: 22px;
			line-height: 1.8;
			display: block;
			width: 100%;
			text-align: center;
			a {
				color: $primary;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.jobModal__footer {
		background: $primary;
		padding: 80px 40px;
		i {
			width: 58px;
			display: block;
			margin: 0 auto;
			img {
				display: block;
				width: 100%;
			}
		}
		p {
			font-weight: $fwLight;
			display: block;
			font-size: 26px;
			color: $secondaryDark;
			margin-top: 30px;
			margin-bottom: 45px;
			text-align: center;
			width: 100%;
		}
		ul {
			display: block;
			text-align: center;
			li {
				@include opacity(1);
				background-color: transparent;
				border: 1px solid transparent;
				display: inline-block;
				margin: 0 15px;
				&:hover {
					@include opacity(0.95);
					border: 1px solid $primaryDark;
					background: $secondary;
				}
				a {
					display: block;
					padding: 15px;
				}
				img {
					display: block;
					width: auto;
					height: 45px;
				}
			}
		}
		.at-share-btn-elements{
			text-align: center;
			a{
				@include transition;
				background-color: transparent;
				border: 1px solid transparent;
				&:hover{
					@include opacity(0.95);
					border: 1px solid $primaryDark;
					background: $secondary !important;
				}
			}
		}
	}
	
	.contatoModal__hero {
		&:after {
			display: block;
			content: '';
			position: absolute;
			left: 30px;
			top: 30px;
			width: 100%;
			height: 100%;
			background: $secondary;
			z-index: -1;
		}
	}
	.contatoModal__formulario {
		box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		background: $primary;
		padding: 30px 15px;
		form, ul, li, label, input, textarea, select {
			width: 100%;
			display: block;
		}
		ul {
		    display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    justify-content: space-around;
		    align-content: flex-start;
		    align-items: flex-start;
		}
		li {
			display: block;
			padding: 15px;
			width: 33.3%;
			position: relative;
		}
		label {
			text-transform: uppercase;
			font-size: 12px;
			color: $secondary;
			font-weight: $fwExtraBold;
			margin-bottom: 6px;
			&.error{
				width: auto;
				position: absolute;
				right: 15px;
				bottom: -5px;
				font-size: 10px;
				font-weight: $fwBold;
				color: $secondaryLight;
			}
		}
		input, textarea, select, option {
			@include transition();
			-webkit-appearance: none;
			-moz-appearance:    none;
			appearance:         none;
			border-bottom: 1px solid $secondaryLight;
			outline: 0;
			height: 42px;
			line-height: 42px;
			padding: 0 15px;
			background-color: transparent;
			font-size: 16px;
			color: $secondaryLight;
			overflow: auto;
			@include scroll($bg: $secondary, $bar: $primaryLight, $size: 5px, $radius: 0px);
			&:focus, &:active {
				background-color: $primaryLight;
				color: $secondaryDark;
			}
		}
		textarea {
			max-width: 100%;
			min-width: 100%;
			min-height: 50px;
			line-height: 25px;
			&:focus, &:active {
				min-height: 100px;
			}
		}
		input:-webkit-autofill, 
		textarea:-webkit-autofill, 
		select:-webkit-autofill {
			background: $primary !important;
		}
		button {
			@include btnContato();
			background: $secondary;
			color: $branco;
			cursor: pointer;
			float: right;
			&:hover {
				background: $secondaryLight;
				color: $branco;
			}
		}
	}
}

.c-stsSend{
	text-align: right;
	span{
		font-weight: bold;
		&.c-stsSend__feedback--success{
			color:#4caf50;
		}
		&.c-stsSend__feedback--error{
			color:#ff5722;
		}
		&.c-stsSend__feedback--load{
			color:$secondaryLight;
		}
	}
}

#contatoModal {
	display: flex;
	align-items: center;
}