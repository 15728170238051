@mixin btnContato() {
	@include transition($ease: ease-in-out, $duration: 150ms);
	@include transforms(scale(1), center);
	background: $primary;
	color: $secondary;
	display: block;
	font-size: 12px;
	font-weight: $fwExtraBold;
	height: 40px;
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;
	width: 170px;
	&:hover {
		@include transforms(scale(1.05), center);
		background: $primaryLight;
		color: $secondaryDark;
	}
}

@mixin titleMaster($color: $branco) {
	font-size: 115px;
	line-height: 1;
	width: 100%;
	color: $color;
	display: block;
	font-weight: $fwThin;
	text-transform: uppercase;
}

@mixin subTitleMaster() {
	color: $textDark;
	width: 100%;
	display: block;
	font-size: 30px;
	font-weight: $fwRegular;
	line-height: 1.2;
	max-width: 780px;
	margin-top: 10px;
}

@mixin comicCardPadrao() {
	@include roundedAll(2px);
	@include transition();
	background: $secondary;
	content: '';
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 3px;
	top: 3px;
	z-index: 1;
}


