// view control
@mixin u-showElement() {
	@include opacity(); 
	@include transition();
	visibility: visible;
}
.u-showElement {
	@include opacity(); 
	@include transition();
	visibility: visible;
}
@mixin u-hideElement() {
	@include opacity($opacity: 0); 
	@include transition();
	visibility: hidden;
}
.u-hideElement {
	@include opacity($opacity: 0); 
	@include transition();
	visibility: hidden;
}
.u-dnone {display: none !important;}
.u-dblock {display: block !important;}

// overflow control
.u-overflowBlocked {overflow: hidden;}
.u-overflowBlocked_y {overflow-y: hidden;}
.u-overflowBlocked_x {overflow-x: hidden;}

// containers and heros boxes
.u-container, .u-containerBig, .u-maxRow {
	display: block;
	margin: 0 auto;
	position: relative;
	width: 100%;
}
.u-containerBig, .u-container {padding: 0 15px;}
.u-container {max-width: $containerWidth;}
.u-containerBig {max-width: $containerBigWidth;}
.u-maxRow {
	@include transition();
	max-width: $maxRowWidth;
	right: 0;
	&.nav__is-active {
		right: 300px;
	}
}

// default widths - used majority in float blocks
.u-w100 {width: 100% !important;}
.u-w80 {width: 80%;}
.u-w75 {width: 75%;}
.u-w70 {width: 70%;}
.u-w66 {width: 66.6%;}
.u-w50 {width: 50%;}
.u-w33 {width: 33.3%;}
.u-w30 {width: 30%;}
.u-w25 {width: 25%;}
.u-w20 {width: 20%;}