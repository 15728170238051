// Colors
$primaryLight: #ffe861;
$primary: #ffdc38;
$primaryDark: #dec321;

$secondaryLight: #842865;
$secondary: #6c1c53;
$secondaryDark: #251821;

$branco: #fff;
$branco2: #f4f4f4;
$branco3: #f8f8f8;

$mutedGray: #ededed;
$lightGray: #e7e7e7;
$mediumGray: #c5c5c5;
$darkGray: #4c4c4c;
$baseGray: #898989;

// Sizes
$containerWidth: 1340px;
$containerBigWidth: 1440px;
$maxRowWidth: 100%;

// text
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,700,800');
$text: #9c9c9c;
$textDark: #686868;
$fontFamily__default: 'Raleway', 'Roboto', 'Arial', 'Calibri', sans-serif;
$fwThin: 100; 
$fwLight: 300; 
$fwRegular: 400; 
$fwBold: 700; 
$fwExtraBold: 800;