.c-hamburguer {
    display: block;
    position: relative;
    width: 40px;
    height: 28px;
    top: 8px;
    z-index: 4;
    .c-hamburguer__hero {
        @include transforms($property: rotate(0deg));
        @include transition();
        cursor: pointer;
        display: block;
        height: 28px;
        position: relative;
        width: 40px;
        .bar {
            @extend .u-showElement;
            @include roundedAll(1px);
            @include transforms($property: rotate(0deg));
            @include transition();
            background: $branco;
            display: block;
            height: 4px;
            position: absolute;
            width: 100%;
            &.bar_1 {
                top: 0;
                left: 0;
            }
            &.bar_2 {
                top: 12px;
                left: 0;
            }
            &.bar_3 {
                top: 24px;
                left: 0;
            }
        }
    }
    &.is-active {
        .c-hamburguer__hero {
            @include transforms($property: rotate(-90deg) scale(1));
            .bar {
                background: $secondary;
            }
        }
    }
}